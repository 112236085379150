import Vue from "vue";
import store from "@/store";

import { forEachCartItem } from "~/service/ebsn.js";

import get from "lodash/get";
import join from "lodash/join";
import round from "lodash/round";
export default {
  data() {
    return { inizialized: null };
  },
  // eslint-disable-next-line no-unused-vars
  setInitialized(value) {},
  getInitialized() {},
  configureCustomMap() {
    if (window.dataLayer) {
      window.dataLayer.push({
        store: global.config.defaultStoreName,
        client: typeof cordova !== "undefined" ? "app" : "site"
      });
    }
  },
  initCustomDimension(cart) {
    if (window.dataLayer) {
      let payload = {};
      if (cart) {
        payload = {
          user_id: cart.user.userId,
          fidelity_card: cart.user.fidelityCard,
          store: cart.store.name,
          warehouse: cart.warehouse.name,
          delivery_service: this.decodeDeliveryService(cart),
          client: typeof cordova !== "undefined" ? "app" : "site"
        };
      } else {
        payload = {
          store: global.config.defaultStoreName,
          client: typeof cordova !== "undefined" ? "app" : "site"
        };
      }
      window.dataLayer.push(payload);
    }
  },
  trackEvent(event, type, value) {
    window.dataLayer = window.dataLayer || [];
    let data = {};
    // If we don't have an event it's like a custom dimension, at least could be used for that.
    if (event) {
      data["event"] = event;
    }
    if (type && value) {
      data[type] = value;
    }
    window.dataLayer.push(data);
  },
  purchase(order) {
    try {
      const total = this.round(order.grossTotal);
      if (window.dataLayer) {
        // const products = this.getItems(order);
        const productsGA4 = this.getItemsGA4(order);
        const deliveredPackageTotal = this.round(order.deliveredPackageTotal);

        const payload = {
          event: "purchase",
          ecommerce: {
            transaction_id: order.orderId,
            affiliation: this.decodeAffiliation(order),
            value: total,
            tax: this.round(order.taxTotal),
            sacchetti: deliveredPackageTotal,
            shipping: this.round(order.deliveryFee),
            currency: "EUR",
            payment_type: order.paymentTypeId,
            // coupon: "???",
            items: productsGA4

            // purchase: {
            //   actionField: {
            //     id: order.orderId,
            //     affiliation: this.decodeAffiliation(order),
            //     revenue: total,
            //     tax: this.round(order.taxTotal),
            //     sacchetti: deliveredPackageTotal,
            //     shipping: this.round(order.deliveryFee),
            //     currency: "EUR"
            //     // coupon: "???",
            //   },
            //   products: products
            // }
          }
        };
        //global.EventBus.$emit("purchase", payload);

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
      }
      if (Vue.analytics && Vue.analytics.fbq) {
        Vue.analytics.fbq.event("Purchase", { currency: "EUR", value: total });
      }
    } catch (err) {
      console.log(err);
    }
  },
  refund(order) {
    if (window.dataLayer) {
      const total = this.round(order.grossTotal);
      const productsGA4 = this.getItemsGA4(order);
      const deliveredPackageTotal = this.round(order.deliveredPackageTotal);

      const payload = {
        event: "refund",
        ecommerce: {
          transaction_id: order.orderId,
          affiliation: this.decodeAffiliation(order),
          value: total,
          tax: this.round(order.taxTotal),
          sacchetti: deliveredPackageTotal,
          shipping: this.round(order.deliveryFee),
          currency: "EUR",
          payment_type: order.paymentTypeId,
          // coupon: "???",
          items: productsGA4
          // refund: {
          //   actionField: {
          //     id: order.orderId
          //   }
          // }
        }
      };
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  viewCart(cart) {
    try {
      if (window.dataLayer) {
        //test-tigros.digitelematica.org/verdura/pomodori-e-pomodorini
        // const products = this.getItems(cart);
        const productsGA4 = this.getItemsGA4(cart);

        const payload = {
          event: "view_cart",
          ecommerce: {
            items: productsGA4
          }
        };
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
        global.EventBus.$emit("beginCheckout", payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  beginCheckout(cart) {
    try {
      if (window.dataLayer) {
        // const products = this.getItems(cart);
        const productsGA4 = this.getItemsGA4(cart);

        const payload = {
          event: "begin_checkout",
          ecommerce: {
            items: productsGA4
          }
        };
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
        global.EventBus.$emit("beginCheckout", payload);
      }
    } catch (err) {
      console.log(err);
    }
  },

  // [UA→GA4] Migrate ecommerce data collection from UA to GA4
  // checkout_progress (this is merged with begin_checkout in GA4)
  // https://support.google.com/analytics/answer/10119380?hl=en
  checkoutProgress(cart) {
    try {
      if (window.dataLayer) {
        // const products = this.getItems(cart);
        const productsGA4 = this.getItemsGA4(cart);
        let payload = {
          event_category: "ecommerce",
          items: productsGA4,
          value: this.round(cart.grossTotal),
          event: "checkout_progress"
        };
        global.EventBus.$emit("checkoutProgress", payload);

        // window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  // [UA→GA4] Migrate ecommerce data collection from UA to GA4
  // set_checkout_option (this is merged with begin_checkout in GA4)
  // https://support.google.com/analytics/answer/10119380?hl=en
  checkoutOptions(step, option, value) {
    try {
      if (window.dataLayer) {
        const payload = {
          event_category: "ecommerce",
          checkout_step: step,
          checkout_option: option,
          value: value,
          event: "set_checkout_option",
          method: value
        };
        // window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  addPaymentInfo(cart, payment_type) {
    // const products = this.getItems(cart);
    const productsGA4 = this.getItemsGA4(cart);

    const payload = {
      event: "add_payment_info",
      ecommerce: {
        value: this.round(cart.grossTotal),
        currency: "EUR",
        payment_type: payment_type,
        // coupon: "???",
        items: productsGA4
        // checkout: {
        //   actionField: {
        //     step: "2"
        //   },
        //   products: products
        // }
      }
    };

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push(payload);
  },
  viewPromotions(proposal, slot, products) {
    try {
      if (window.dataLayer) {
        let payload = {
          event: "view_promotion",
          ecommerce: {
            promotion_id: proposal.id,
            promotion_name:
              proposal.name || proposal.descr || proposal.imgDescription,
            creative_name:
              proposal.imgDescription || this.getFileName(proposal.img),
            creative_slot: slot
          }
        };

        if (products) {
          const productsGA4 = products.map(i =>
            this.getItemGA4(i, "Promotion", slot)
          );
          if (productsGA4.length) {
            payload.ecommerce.items = productsGA4;
          }
        }
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  // Pasare index come param...
  clickPromotion(proposal, slot, products) {
    try {
      if (window.dataLayer) {
        const payload = {
          event: "select_promotion",
          ecommerce: {
            promotion_id: proposal.id,
            promotion_name:
              proposal.name || proposal.descr || proposal.imgDescription,
            creative_name:
              proposal.imgDescription || this.getFileName(proposal.img),
            creative_slot: slot
          }
        };
        if (products) {
          const productsGA4 = products.map(i =>
            this.getItemGA4(i, "Promotion", slot)
          );
          if (productsGA4.length) {
            payload.ecommerce.items = productsGA4;
          }
        }
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  addToCart(items, listName, listPosition) {
    if (window.dataLayer) {
      // const products = items.map(i => this.getItem(i, listName, listPosition));
      const productsGA4 = items.map(i =>
        this.getItemGA4(i, listName, listPosition)
      );

      const payload = {
        event: "add_to_cart",
        ecommerce: {
          items: productsGA4
          // add: {
          //   products: products
          // }
        }
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  removeFromCart(items, listName, listPosition) {
    try {
      if (window.dataLayer) {
        // const products = items.map(i =>
        //   this.checkItemArray(i, listName, listPosition)
        // );
        const productsGA4 = items.map(i =>
          this.checkItemGA4Array(i, listName, listPosition)
        );

        const payload = {
          event: "remove_from_cart",
          ecommerce: {
            items: productsGA4
            // remove: {
            //   products: products
            // }
          }
        };

        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push(payload);
      }
    } catch (err) {
      console.log(err);
    }
  },

  // checkItemArray(item, listName, listPosition) {
  //   if (item && item.length > 1) {
  //     return this.getItems(item, listName, listPosition);
  //   } else {
  //     return this.getItem(item, listName, listPosition);
  //   }
  // },

  checkItemGA4Array(item, listName, listPosition) {
    if (item && item.length > 1) {
      return this.getItemsGA4(item, listName, listPosition);
    } else {
      return this.getItemGA4(item, listName, listPosition);
    }
  },

  addProductToList(items, listName, listPosition) {
    if (window.dataLayer) {
      // const products = items.map(i => this.getItem(i, listName, listPosition));
      const productsGA4 = items.map(i =>
        this.getItemGA4(i, listName, listPosition)
      );

      const payload = {
        event: "add_to_wishlist",
        ecommerce: {
          currency: "EUR",
          value: productsGA4[0].price,
          items: productsGA4
        }
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  removeProductToList(items, listName, listPosition) {
    if (window.dataLayer) {
      // const products = items.map(i => this.getItem(i, listName, listPosition));
      const productsGA4 = items.map(i =>
        this.getItemGA4(i, listName, listPosition)
      );

      const payload = {
        event: "remove_from_wishlist",
        ecommerce: {
          currency: "EUR",
          value: productsGA4[0].price,
          items: productsGA4
        }
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  viewProducts(items, listName, listOffset) {
    if (window.dataLayer) {
      let offset = 1 + listOffset;
      let name = listName || "categoria_3";
      // const products = items.map((i, index) =>
      //   this.getItem(i, name, index + offset)
      // );
      const productsGA4 = items.map((i, index) =>
        this.getItemGA4(i, name, index + offset)
      );

      const payload = {
        event: "view_item_list",
        ecommerce: {
          items: productsGA4
          // impressions: {
          //   products: products
          // }
        }
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  viewProductDetail(product) {
    if (window.dataLayer) {
      // const products = [this.getItem(product)];
      const productsGA4 = [this.getItemGA4(product)];

      const payload = {
        event: "view_item",
        ecommerce: {
          items: productsGA4
          // detail: {
          //   products: products
          // }
        }
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  // Maybe this? https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=en#select_item
  clickProduct(product, listName, listPosition) {
    if (window.dataLayer) {
      // const products = [this.getItem(product, listName, listPosition)];
      const productsGA4 = [this.getItemGA4(product, listName, listPosition)];

      const payload = {
        event: "select_item",
        ecommerce: {
          items: productsGA4
          // click: {
          //   actionField: { list: listName },
          //   products: products
          // }
        }
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push(payload);
    }
  },
  selectWarehouse(cart) {
    if (window.dataLayer) {
      const payload = {
        event: "warehouseChange",
        value: cart.warehouse?.name
      };
      window.dataLayer.push(payload);
      this.initCustomDimension(cart);
    }
  },
  selectStore(cart) {
    if (window.dataLayer) {
      this.initCustomDimension(cart);
      const payload = {
        event: "store_change",
        value: cart.store.name
      };
      window.dataLayer.push(payload);
      this.initCustomDimension(cart);
    }
  },
  selectAddress(cart) {
    if (window.dataLayer) {
      this.initCustomDimension(cart);
      const payload = {
        event: "address_change",
        value: cart.shippingAddress.addressName
      };
      window.dataLayer.push(payload);
      this.initCustomDimension(cart);
    }
  },
  // eslint-disable-next-line no-unused-vars
  selectTimeslot(cart) {
    if (window.dataLayer) {
      const payload = {
        event: "timeslot_change",
        value:
          cart.timeslot.date +
          " " +
          cart.timeslot.beginTime +
          " " +
          cart.timeslot.endTime
      };
      window.dataLayer.push(payload);
      this.initCustomDimension(cart);
    }
  },
  login(cart, method = "form", autoLogin) {
    if (window.dataLayer) {
      this.initCustomDimension(cart);
      const payload = {
        event: "login",
        user_id: cart.user.userId,
        autoLogin: autoLogin || false
      };
      if (method) {
        payload.method = method;
      }
      window.dataLayer.push(payload);
    }
  },
  logout() {
    if (window.dataLayer) {
      const payload = { event: "logout" };
      window.dataLayer.push(payload);
      this.initCustomDimension();
    }
  },
  signUp(method, userId = null) {
    if (window.dataLayer) {
      const payload = {
        event: "sign_up",
        method: method,
        user_id: userId
      };
      window.dataLayer.push(payload);
    }
  },
  share(method) {
    if (window.dataLayer) {
      const payload = {
        event: "share",
        method: method
      };
      window.dataLayer.push(payload);
    }
  },
  search(query) {
    if (window.dataLayer) {
      const payload = { event: "search", search_term: query };
      window.dataLayer.push(payload);
    }
  },
  search_null(query) {
    if (window.dataLayer) {
      const payload = { event: "search_0_results", null_search_term: query };
      window.dataLayer.push(payload);
    }
  },
  // HELPER METHODS
  // getItems(order) {
  //   if (window.dataLayer) {
  //     let items = [];
  //     forEachCartItem(order, item => {
  //       items.push(this.getItem(item));
  //     });
  //     return items;
  //   }
  // },
  getItemsGA4(order) {
    if (window.dataLayer) {
      let items = [];
      forEachCartItem(order, item => {
        items.push(this.getItemGA4(item));
      });
      return items;
    }
  },
  getFileName(url) {
    var result = "No location";
    if (url && url.length) {
      var n = url.lastIndexOf("/");
      result = url.substring(n + 1);
    }
    return result;
  },
  // getItem(item, listName, listPosition) {
  //   let product = item.product ? item.product : item;
  //   let analyticsItem = {
  //     id: product.code,
  //     name: this.getProductName(product),
  //     brand: product.shortDescr,
  //     category: this.getCategoryName(product.categoryId),
  //     variant: product.description
  //   };
  //   if (listName) {
  //     analyticsItem.list_name = listName;
  //   }
  //   if (listPosition) {
  //     analyticsItem.list_position = listPosition;
  //   }
  //   if (item.product) {
  //     (analyticsItem.quantity = this.getItemQuantity(item)),
  //       (analyticsItem.price = this.getItemPrice(item));
  //   }
  //   return analyticsItem;
  // },
  getItemGA4(item, listName, listPosition) {
    let product = item.product ? item.product : item;
    let analyticsItem = {
      // Schema for GA4
      item_id: product.code,
      item_name: this.getProductName(product),
      item_brand: product.shortDescr,
      item_variant: product.description,
      currency: "EUR",
      ...this.getCategoryPath(product.categoryId)
    };
    //aggiungere nomi categorie
    if (listName) {
      // Schema for GA4
      analyticsItem.item_list_name = listName;
    }
    if (listPosition) {
      // Schema for GA4
      analyticsItem.index = listPosition;
      analyticsItem.location_id = listPosition;
    }
    if (item.product) {
      (analyticsItem.quantity = this.getItemQuantity(item)),
        (analyticsItem.price = this.getItemPrice(item));
    }
    return analyticsItem;
  },
  getItemPrice(item) {
    if (window.dataLayer) {
      if (item.grossTotal) {
        return Math.abs(
          this.round(item.grossTotal / this.getItemQuantity(item))
        );
      } else {
        return item.product.priceDisplay;
      }
    }
  },
  getItemQuantity(item) {
    if (window.dataLayer) {
      let quantity = 1;
      if (item.product.productInfos.TIPOLOGIA === "Sfuso") {
        quantity = 1;
      } else if (item.quantity) {
        quantity = item.quantity;
      }
      return quantity;
    }
  },
  getProductName(product) {
    if (window.dataLayer) {
      var params = [product.name, product.descr, product.shortDescr];
      return join(params, " ");
    }
  },
  getCategoryName(categoryId) {
    try {
      let category = store.getters["category/lookupCategory"](categoryId);
      return category?.name;
    } catch (err) {
      console.log(err);
      return "";
    }
  },
  getCategoryPath(categoryId) {
    try {
      let categories = store.getters["category/lookupCategoryPath"](categoryId);
      let ret = {};
      if (categories.length) {
        if (categories.length > 0) ret.item_category = categories[0].name;
        if (categories.length > 1) ret.item_category2 = categories[1].name;
        if (categories.length > 2) ret.item_category3 = categories[2].name;
        if (categories.length > 3) ret.item_category4 = categories[3].name;
        if (categories.length > 4) ret.item_category5 = categories[4].name;
      } else {
        ret.item_category = "Custom category";
      }

      return ret;
    } catch (err) {
      console.log(err);
      return {};
    }
  },
  round(price) {
    if (window.dataLayer) {
      return round(price, 2);
    }
  },
  decodeAffiliation(cart) {
    if (window.dataLayer) {
      if (cart.shippingAddress.addressTypeId === 3) {
        return get(cart, "shippingAddress.addressName", "Drive");
      } else {
        return get(cart, "warehouse.name", "Home");
      }
    }
  },
  decodeDeliveryService(cart) {
    if (window.dataLayer) {
      if (cart.shippingAddress.deliveryServiceId === 1) {
        return "Drive";
      } else if (cart.shippingAddress.deliveryServiceId === 2) {
        return "Home";
      } else if (cart.shippingAddress.deliveryServiceId === 3) {
        return "Rider";
      } else if (cart.shippingAddress.deliveryServiceId === 10002) {
        return "Locker";
      } else {
        return "None";
      }
    }
  },
  cartEvent(eventName, cartItems) {
    if (window.dataLayer) {
      const payload = {
        event: eventName
      };

      let items = [];
      if (cartItems && cartItems.length) {
        for (const cartItem of cartItems) {
          items.push(this.getItemGA4(cartItem));
        }
      }
      if (items.length) {
        payload.items = items;
      }
      window.dataLayer.push(payload);
    }
  }
};
